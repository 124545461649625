import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import "bootstrap/dist/css/bootstrap.min.css"
import 'bootstrap/dist/js/bootstrap.bundle.min.js';

import { WebSocketPlugin } from './plugins/ws';

import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import 'bootstrap-icons/font/bootstrap-icons.css';
import './styles.css';

createApp(App)
  .use(store)
  .use(router)
  .use(WebSocketPlugin)
  .component('Datepicker', Datepicker)
  .mount('#app')
