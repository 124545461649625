<template>
  <div class="col-md-4 offset-md-4">
    <div class="login-form mt-4 p-4">
      <div class="row g-3">
        <h4>Добро пожаловать в Хлебушек!</h4>
        <div class="col-12">
          <label>Почта</label>
          <input type="email" name="email" class="form-control" placeholder="Email" v-model="email">
        </div>
        <div class="col-12">
          <label>Пароль</label>
          <input type="password" name="password" class="form-control" placeholder="Password" v-model="password">
        </div>
        <div class="col-12 error-placeholder">
          <error-label/>
        </div>
        <div class="col-12">
          <button type="submit" class="btn btn-dark float-end" @click="handleClickActionLogin" :disabled="disabledSubmit">Вход</button>
        </div>
      </div>
      <hr class="mt-4">
      <div class="col-12 mode-switcher-placeholder">
        <p class="text-center mb-0">Ещё не зарегистрированы? <a href="#" @click="handleClickModeRegister" data-mode="register">Регистрация</a></p>
      </div>
    </div>
  </div>
</template>

<script>
import ErrorLabel from "@/components/ui/ErrorLabel.vue";
import {latestMessage} from "@/plugins/ws";
import {mapActions, mapState} from "vuex";

export default {
  name: 'ComponentLogin',
  components: {
    ErrorLabel,
  },
  data() {
    return {
      email: '',
      password: '',
      latestMessageValue: latestMessage,
      disabledSubmit: false,
    }
  },
  computed: {
    ...mapState(['errorReason']),
  },
  emits: ['switchMode'],
  watch: {
    email () {
      this.unsetErrorReason();
    },
    password () {
      this.unsetErrorReason();
    },
    latestMessageValue(data) {
      switch (data['signal']) {
        case 'auth-login':
          this.signalLogin({
            idUser: data.auth.idUser,
            name: data.auth.name,
            email: data.auth.email,
            admin: data.auth.admin,
            theme: data.auth.theme,
            confirmEmail: data.auth.confirmEmail,
            register: data.auth.register,
            newPassword: data.auth.newPassword,
            restorePassword: data.auth.restorePassword,
          });
          break;
        case 'auth-login-fail':
          this.signalLoginFail(data['reason']);
          break;
      }
    },
  },
  methods: {
    ...mapActions(['setErrorReason', 'unsetErrorReason']),
    handleClickActionLogin() {
      this.disabledSubmit = true;
      this.$wsSend('authLogin', {
        email: this.email,
        password: this.password,
      });
    },
    handleClickModeRegister() {
      this.$emit('switchMode', 'register');
    },
    signalLogin(payload) {
      this.unsetErrorReason();
      this.disabledSubmit = false;
      this.$store.dispatch('wsSetUser', payload);
    },
    signalLoginFail(payload) {
      this.setErrorReason(payload);
      this.disabledSubmit = false;
    },
  },
}
</script>

<style scoped>

</style>