<template>
  <div class="col-md-4 offset-md-4">
    <div class="login-form mt-4 p-4">
      <div class="row g-3">
        <h4>Регистрация в Хлебушке</h4>
        <div class="col-12">
          <label>Почта</label>
          <input type="email" name="email" class="form-control" placeholder="Email" v-model="email">
        </div>
        <div class="col-12">
          <label>Имя</label>
          <input type="text" name="name" class="form-control" placeholder="Name" v-model="name">
        </div>
        <div class="col-12">
          <label>Пароль</label>
          <input type="password" name="password" class="form-control" placeholder="Password" v-model="password">
        </div>
        <div class="col-12 error-placeholder">
          <error-label/>
        </div>
        <div class="col-12">
          <button type="submit" class="btn btn-dark float-end" @click="handleClickActionRegister" :disabled="disabledSubmit">Регистрация</button>
        </div>
      </div>
      <hr class="mt-4">
      <div class="col-12 mode-switcher-placeholder">
        <p class="text-center mb-0">Есть аккаунт? <a href="#" @click="handleClickModeRestore" data-mode="restore">Восстановить</a> <a href="#" @click="handleClickModeLogin" data-mode="login">Войти</a></p>
      </div>
    </div>
  </div>
</template>

<script>
import ErrorLabel from "@/components/ui/ErrorLabel.vue";
import {latestMessage} from "@/plugins/ws";
import {mapActions, mapState} from "vuex";

export default {
  name: "ComponentRegister",
  components: {
    ErrorLabel,
  },
  data() {
    return {
      email: '',
      name: '',
      password: '',
      latestMessageValue: latestMessage,
      disabledSubmit: false,
    }
  },
  computed: {
    ...mapState(['errorReason']),
  },
  emits: ['switchMode'],
  watch: {
    email () {
      this.unsetErrorReason();
    },
    name () {
      this.unsetErrorReason();
    },
    password () {
      this.unsetErrorReason();
    },
    latestMessageValue(data) {
      switch (data['signal']) {
        case 'auth-register':
          this.signalRegister({
            idUser: data['idUser'],
            name: data['name'],
            email: data['email'],
          });
          break;
        case 'auth-register-fail':
          this.signalRegisterFail(data['reason']);
          break;
      }
    },
  },
  methods: {
    ...mapActions(['setErrorReason', 'unsetErrorReason']),
    handleClickActionRegister() {
      this.$wsSend('authRegister', {
        email: this.email,
        name: this.name,
        password: this.password,
      });
      this.disabledSubmit = true;
    },
    handleClickModeRestore() {
      this.$emit('switchMode', 'restore');
    },
    handleClickModeLogin() {
      this.$emit('switchMode', 'login');
    },
    signalRegister(payload) {
      this.unsetErrorReason();
      this.disabledSubmit = false;
      this.$store.dispatch('wsSetUser', {
        idUser: payload.idUser,
        email: payload.email,
        name: payload.name,
        admin: false,
        confirmEmail: false,
      });
      this.$emit('switchMode', 'registerCode');
    },
    signalRegisterFail(payload) {
      this.setErrorReason(payload);
      this.disabledSubmit = false;
    },
  },
}
</script>

<style scoped>

</style>