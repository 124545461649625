<template>
  <div class="col-md-4 offset-md-4">
    <div class="login-form mt-4 p-4">
      <div class="row g-3">
        <h4>Задайте новый пароль</h4>
        <p>Вы подтвердили, что вы являетесь владельцем этого аккаунта.</p>
        <div class="col-12">
          <label>Новый пароль</label>
          <input type="text" name="password" class="form-control" placeholder="New password" v-model="password">
        </div>
        <div class="col-12 error-placeholder">
          <error-label/>
        </div>
        <div class="col-12">
          <button type="submit" class="btn btn-dark float-end ms-3" @click="handleClickActionRestoreSetPassword">Продолжить</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {latestMessage} from "@/plugins/ws";
import ErrorLabel from "@/components/ui/ErrorLabel.vue";
import {mapActions, mapState} from "vuex";

export default {
  name: "RestoreConfirm",
  components: {ErrorLabel},
  data() {
    return {
      password: '',
      latestMessageValue: latestMessage,
    }
  },
  computed: {
    ...mapState(['errorReason']),
  },
  watch: {
    latestMessageValue(data) {
      switch (data['signal']) {
        case 'auth-restore-set-password':
          this.signalRestoreSetPassword();
          break;
        case 'auth-restore-set-password-fail':
          this.signalRestoreSetPasswordFail(data['reason']);
          break;
      }
    },
  },
  emits: ['switchMode'],
  methods: {
    ...mapActions(['setErrorReason', 'unsetErrorReason']),
    handleClickActionRestoreSetPassword() {
      this.$wsSend('authRestoreSetPassword', {
        newPassword: this.password,
      });
    },
    signalRestoreSetPasswordFail(payload) {
      this.setErrorReason(payload);
    },
    signalRestoreSetPassword() {
      this.unsetErrorReason();
      this.$store.dispatch('wsRestoreSuccess');
      this.$emit('switchMode', 'restoreSuccess');
    },
  },
}
</script>

<style scoped>

</style>