<template>
  <data-languages/>
  <data-countries/>
  <data-country-languages/>
  <data-locales/>

  <div class="container" id="mainContainer" :data-loaded="wsLoaded">
    <top-menu
        :isAdminPanel="isAdminPanel"
        @actionLogout="actionLogout"
    />
    <div class="container align-content-center col-md-4 offset-md-4" v-if="!wsConnected || !isSessionInit">
      <div class="spinner-border" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
    <div v-else>
      <auth v-if="(($route.meta.access !== 'guest') && (isUserEmailConfirm === false))" @resubscribePage="resubscribePage"/>
      <div v-else-if="(($route.meta.access === 'admin') && (isAdminPanel === true) && (!admin))">
        <h2>Админка</h2>
        <p>У вас нет доступа сюда</p>
        <router-link :to="{ name: 'Main'}">На главную</router-link>
      </div>
      <div v-else>
        <router-view v-if="wsConnected && isSessionInit"></router-view>
        <div class="text-center" v-if="!wsLoaded">
          <div class="spinner-border" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
      </div>
    </div>
    <footer ref="footer" class="mt-auto">
      <hr/>
      <div class="container text-end">
        <ul class="list-inline">
          <li class="list-inline-item"><router-link class="btn btn-outline-info border-0" :to="{ name: 'About'}">About</router-link></li>
          <li class="list-inline-item"><router-link class="btn btn-outline-info border-0" :to="{ name: 'Terms'}">Terms</router-link></li>
          <li class="list-inline-item"><router-link class="btn btn-outline-info border-0" :to="{ name: 'Privacy'}">Privacy</router-link></li>
          <li class="list-inline-item"><router-link class="btn btn-outline-info border-0" :to="{ name: 'Contacts'}">Contacts</router-link></li>
        </ul>
      </div>
    </footer>
  </div>
</template>

<script>
import Auth from "./components/Auth"
import TopMenu from "./components/TopMenu"
import {latestMessage} from "@/plugins/ws";
import {mapActions, mapGetters, mapState} from "vuex";
import DataLanguages from "@/components/data/languages.vue";
import DataCountries from "@/components/data/countries.vue";
import DataCountryLanguages from "@/components/data/countryLanguages.vue";
import DataLocales from "@/components/data/locales.vue";

export default {
  components: {
    DataLocales,
    DataCountryLanguages,
    DataCountries,
    DataLanguages,
    Auth,
    TopMenu,
  },
  data() {
    return {
      latestMessageValue: latestMessage,
      subscribed: false, // TODO: Probably we should remove it
      timeoutSeconds: 55,

      timeframes: ["1m", "3m", "5m", "15m", "1h", "2h", "4h", "6h", "8h", "12h", "1d", "3d", "1w"],
      candleCounts: [50, 100, 200, 400],

      interval: null,
      lastPing: null,
    }
  },
  computed: {
    ...mapState(['user', 'wsConnected', 'wsSession', 'wsLoaded', 'theme', 'lastTimeBuild']),
    ...mapState(['selected']),
    ...mapState(['pageHidden', 'pageFocus']),
    ...mapGetters(['isSessionInit', 'isUserEmailConfirm']),
    isAdminPanel: function () {
      return this.$route.path.includes('/admin', 0);
    },
    idUser: function () {
      return this.user.idUser;
    },
    admin: function () {
      return this.user.admin;
    },
    block: function () {
      return this.user.block;
    },
  },
  watch: {
    latestMessageValue(data) {
      switch (data['signal']) {
        case 'auth-session':
          this.$store.dispatch('wsSetUser', {
            idUser: data.auth.idUser ?? null,
            email: data.auth.email ?? null,
            name: data.auth.name ?? null,
            theme: data.auth.theme ?? this.theme,
            admin: data.auth.admin ?? null,
            confirmEmail: data.auth.confirmEmail ?? false,
            register: data.auth.register,
            newPassword: data.auth.newPassword,
            restorePassword: data.auth.restorePassword,
            changeEmail: data.auth.changeEmail,
          });
          if (this.lastTimeBuild === null) {
            this.setLastTimeBuild(data.lastTimeBuild);
          } else if (this.lastTimeBuild !== data.lastTimeBuild) {
            this.setLastTimeBuild(data.lastTimeBuild);
            window.location.reload();
          }
          if (!this.subscribed) {
            this.subscribe(this.$route.path);
          }
          break;
        case 'auth-logout':
          this.setAuthMode('login');
          this.wsUnsetUser();
          break;
        case 'profile-name':
          this.$store.dispatch('profileSetName', data.newName);
          break;
        case 'profile-theme':
          this.$store.dispatch('profileSetTheme', data.newTheme);
          break;
        case 'admin-user-update':
          this.$store.dispatch('adminUserUpdate', data);
          break;
        case 'admin-reload':
          if (this.$route.path !== '/admin/tasks') {
            window.location.reload();
          }
          break;
      }
    },
    idUser(to) {
      if ((to !== 0) && (to !== null)) {
        if (!this.subscribed) {
          this.subscribe(this.$route.path);
        }
      }
    },
    $route(to) {
      this.subscribe(to.path);
    },
    block(to) {
      if (to) {
        this.actionLogout();
      }
    },
  },
  methods: {
    ...mapActions(['wsUnsetUser', 'wsSetLoaded', 'setTheme', 'setThemeDoNotShow', 'setAuthMode', 'setLastTimeBuild']),
    ...mapActions(['selectTimeFrame', 'selectCandleCount']),
    ...mapActions(['setPageHidden', 'setPageFocus']),
    actionLogout() {
      this.setAuthMode('login');
      this.$wsSend('authLogout');
    },
    resubscribePage() {
      this.subscribe(this.$route.path);
    },
    subscribe(path) {
      if (!this.wsConnected) {
        return;
      }
      if (
        (this.user.idUser === 0) ||
        (this.user.idUser === null) ||
        (this.user.confirmEmail === false)
      ) {
        if (this.$route.meta.access !== 'guest') {
          this.wsSetLoaded(false);
          console.log('Set loaded false: guest');
          this.$wsSend('unsubscribe');
          this.subscribed = false;
          return;
        }
      }
      if (this.isAdminPanel && !this.user.admin) {
        this.wsSetLoaded(false);
        console.log('Set loaded false: admin');
        this.$wsSend('unsubscribe');
        this.subscribed = false;
        return;
      }
      let subscribe = null;
      let params = [];
      switch (path) {
        case '/':
          return;
        case '/pairs':
          return;
        case '/profile':
          return;
        case '/admin':
          return;
        case '/admin/language':
          return;
        case '/admin/language/country':
          return;
        case '/admin/language/entity':
          return;
        case '/admin/language/page':
          return;
        case '/admin/language/email':
          return;
        case '/admin/indicator':
          return;
        case '/admin/currency':
          return;
        case '/admin/global/currency':
          return;
        case '/admin/global/pair':
          return;
        case '/admin/tradeGroup':
          return;
        case '/admin/setting':
          return;
        case '/admin/exchange':
          return;
        case '/admin/user':
          return;
        case '/admin/blockchain':
          return;
        case '/admin/backup':
          return;
        case '/admin/store':
          return;
        case '/admin/stream':
          return;
        case '/admin/calc':
          return;
        case '/admin/calc/logs':
          return;
        case '/admin/tasks':
          return;
        default:
          switch (this.$route.name) {
            case 'AdminStoreExchange':
              return;
            case 'AdminStreamExchange':
              return;
            case 'Pair':
              return;
            case 'PairGraph':
              return;
            case 'AdminIndicatorRoot':
              return;
            case 'AdminIndicatorParam':
              return;
            case 'AdminIndicatorOut':
              return;
            case 'AdminIndicatorSet':
              return;
            case 'AdminCurrencyRoot':
              return;
            case 'AdminCurrencyBlockchain':
              return;
            case 'AdminExchangeRoot':
              return;
            case 'AdminExchangeCurrency':
              return;
            case 'AdminExchangePair':
              return;
            case 'AdminGlobalPairRoot':
              return;
            case 'AdminGlobalPairExchange':
              return;
            case 'AdminGlobalPairIndicator':
              return;
            case 'AdminGlobalPairCalc':
              return;
            case 'AdminGlobalPairData':
              return;
          }
      }
      if (subscribe === null) {
        this.wsSetLoaded(false);
        console.log('Set loaded false: unsubscribe');
        this.$wsSend('unsubscribe');
        this.subscribed = false;
        return;
      }
      this.subscribed = true;
      this.wsSetLoaded(false);
      console.log('Set loaded false: subscribe');
      this.$wsSend('subscribe', {
        subscribe: subscribe,
        params: params,
      });
    },
    storageUpdate(event) {
      if (event.newValue === event.oldValue) {
        return;
      }
      switch (event.key) {
        case 'bs-theme':
          this.setTheme(event.newValue);
          break;
        case 'bs-theme-do-not-show':
          this.setThemeDoNotShow(event.newValue === 'true');
          break;
        case 'wsSession':
          // TODO: handle this strange situation
          break;
        case 'authMode':
          this.setAuthMode(event.newValue);
          break;
        case 'last-time-build':
          this.setLastTimeBuild(event.newValue);
          break;
        case 'selectedTimeframe':
          // TODO: handle that
          break;
        case 'selectedCandleCount':
          // TODO: handle that
          break;
      }
    },
    eventVisibilitychange() {
      if (document.visibilityState === 'visible') {
        if (this.wsLoaded === false) {
          console.log('Going to resubscribe now');
          this.$wsResubscribe();
        }
        this.setPageHidden(false);
      } else {
        this.setPageHidden(true);
      }
    },
    eventFocus() {
      this.setPageFocus(true);
    },
    eventBlur() {
      this.setPageFocus(false);
    },
    startTimer() {
      this.interval = setInterval(() => {
        if (this.pageHidden) {
          if (new Date() - this.lastPing > this.timeoutSeconds * 2 * 1000) {
            if (this.wsLoaded) {
              this.wsSetLoaded(false);
              console.log('Set loaded false: page hidden timeout [' + (this.timeoutSeconds * 2) + ' seconds]');
              this.$wsUnsubscribe(true);
              this.subscribed = false;
            }
          }
        }
      }, 251);
    },
    stopTimer() {
      if (this.interval) {
        clearInterval(this.interval);
        this.interval = null;
      }
    },
  },

  created() {
    this.selectTimeFrame(
        this.timeframes.includes(localStorage.getItem('selectedTimeframe'))
            ? localStorage.getItem('selectedTimeframe')
            : '1w'
    );
    this.selectCandleCount(
        this.candleCounts.includes(parseInt(localStorage.getItem('selectedCandleCount')))
            ? parseInt(localStorage.getItem('selectedCandleCount'))
            : 100
    );
    this.setTheme(localStorage.getItem('bs-theme') || 'auto');
    this.setThemeDoNotShow(localStorage.getItem('bs-theme-do-not-show') === 'true');
    this.setAuthMode(localStorage.getItem('authMode') || null);
    this.setLastTimeBuild(localStorage.getItem('last-time-build') || null);
    let self = this;
    let pingInterval = null;
    this.$wsSetOnOpen(() => {
      this.$store.commit('setWsConnected', true);
      this.$store.commit('initSession');

      self.$wsSend('authSession', {
        session: this.wsSession,
      });
      let that = this;
      that.lastPing = new Date();
      clearInterval(pingInterval);
      pingInterval = setInterval(() => {
        that.$wsSend('ping', {});
        that.lastPing = new Date();
      }, 1000 * this.timeoutSeconds);

      if (!this.subscribed) {
        this.subscribe(this.$route.path);
      }
    });
    this.$wsSetOnClose((event) => {
      this.subscribed = false;
      clearInterval(pingInterval);
      this.$store.commit('setWsConnected', false);
      console.log('Socket is closed. Reconnect will be attempted in 3 seconds.', event.reason);
      setTimeout(() => {
        if (this.wsConnected) {
          console.warn('WebSocket is already connected. Skip reconnecting.');
          return;
        }
        if (!this.subscribed) {
          this.subscribe(this.$route.path);
        }
        this.$wsReConnect();
      }, 3000);
    });
    this.$wsSetOnError((error) => {
      console.error('WebSocket error', error);
    });
    this.$wsSetOnMessage((event) => {
      let json = JSON.parse(event.data);
      let signal = json.signal;
      delete json.signal;

      this.$store.dispatch('wsMessageHandler', {signal, json});
    });
    this.$wsReConnect();
  },
  mounted() {
    window.addEventListener('storage', this.storageUpdate);
    window.addEventListener('visibilitychange', this.eventVisibilitychange);
    window.addEventListener('focus', this.eventFocus);
    window.addEventListener('blur', this.eventBlur);
    this.startTimer();
  },
  unmounted() {
    window.removeEventListener('storage', this.storageUpdate);
    window.removeEventListener('visibilitychange', this.eventVisibilitychange);
    window.removeEventListener('focus', this.eventFocus);
    window.removeEventListener('blur', this.eventBlur);
    this.stopTimer();
  },
}
</script>

<style scoped>
#mainContainer {
  display: flex;
  flex-direction: column;
  height: 100vh;
}
#mainContainer > div:last-child {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}
</style>
