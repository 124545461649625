<template>
  <div/>
</template>

<script>
import {latestMessage} from "@/plugins/ws";
import {mapActions} from "vuex";

export default {
  name: "dataLocales",
  data() {
    return {
      latestMessageValue: latestMessage,
    }
  },
  watch: {
    latestMessageValue(data) {
      switch (data['signal']) {
        case 'language-locale-list':
          this.wsLanguageLocales({locales: data['locales']});
          break;
        case 'language-locale-create':
        case 'language-locale-create-success':
          this.wsLanguageLocalesCreate({
            idLocale: data.idLocale,
            idLanguage: data.idLanguage,
            idCountry: data.idCountry,
            formatDate: data.formatDate,
            formatTime: data.formatTime,
            formatNumber: data.formatNumber,
            formatAddress: data.formatAddress,
            formatTelephone: data.formatTelephone,
            metric: data.metric,
            collation: data.collation,
            firstWeekDay: data.firstWeekDay,
          });
          break;
        case 'language-locale-update':
        case 'language-locale-update-success':
          this.wsLanguageLocalesUpdate({
            idLocale: data.idLocale,
            idCountry: data.idCountry,
            formatDate: data.formatDate,
            formatTime: data.formatTime,
            formatNumber: data.formatNumber,
            formatAddress: data.formatAddress,
            formatTelephone: data.formatTelephone,
            metric: data.metric,
            collation: data.collation,
            firstWeekDay: data.firstWeekDay,
          });
          break;
        case 'language-locale-delete':
        case 'language-locale-delete-success':
          this.wsLanguageLocalesDelete({
            idLocale: data.idLocale,
          });
          break;
        case 'language-locale-lock':
        case 'language-locale-lock-success':
          this.wsLanguageLocalesLock({
            idLocale: data.idLocale,
          });
          break;
        default:
          if (typeof data['locales'] != 'undefined') {
            this.wsLanguageLocales({locales: data['locales']});
          }
          break;
      }
    },
  },
  methods: {
    ...mapActions([
      'wsLanguageLocales',
      'wsLanguageLocalesCreate',
      'wsLanguageLocalesUpdate',
      'wsLanguageLocalesDelete',
      'wsLanguageLocalesLock',
    ]),
  },
}
</script>
