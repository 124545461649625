<template>
  <div class="col-md-4 offset-md-4">
    <div class="login-form mt-4 p-4">
      <div class="row g-3">
        <h4>Подтвердите, что вы владелец указанной почты</h4>
        <p>Вам на почту отправлено письмо с кодом, укажите пожалуйста тут этот код.</p>
        <p>Если что-то пошло не так, можно <a href="#" @click="handleClickActionRegisterResend" id="btn-resend">Отправить</a> повторно.</p>
        <div class="col-12 status-placeholder">
          <status-label :message="emailProgress" :style="emailProgressStyle"></status-label>
        </div>
        <div class="col-12">
          <label>Почта</label>
          <input type="email" name="email" class="form-control" placeholder="Email" v-model="email" readonly disabled>
        </div>
        <div class="col-12">
          <label>Код</label>
          <input type="text" name="code" class="form-control" placeholder="Code" v-model="code">
        </div>
        <div class="col-12 error-placeholder">
          <error-label/>
        </div>
        <div class="col-12">
          <button type="submit" class="btn btn-dark float-end ms-3" @click="handleClickActionRegisterConfirm">Подтвердить</button>
          <button type="submit" class="btn btn-dark float-end" @click="handleClickActionRegisterConfirmCancel">Отмена</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ErrorLabel from "@/components/ui/ErrorLabel.vue";
import {latestMessage} from "@/plugins/ws";
import {mapActions, mapState} from "vuex";
import StatusLabel from "@/components/ui/StatusLabel.vue";

export default {
  name: "RegisterCode",
  components: {
    StatusLabel,
    ErrorLabel,
  },
  data() {
    return {
      email: '',
      code: '',
      emailProgress: 'Prepare to sending ...',
      emailProgressStyle: 'bg-info',
      latestMessageValue: latestMessage,
    }
  },
  computed: {
    ...mapState(['user', 'errorReason']),
  },
  watch: {
    email () {
      this.unsetErrorReason();
    },
    code () {
      this.unsetErrorReason();
    },
    latestMessageValue(data) {
      switch (data['signal']) {
        case 'auth-register-confirm':
          this.signalRegisterConfirm();
          break;
        case 'auth-register-confirm-fail':
          this.signalRegisterConfirmFail(data['reason']);
          break;
        case 'auth-register-email-progress':
          this.signalEmailProgress(data['emailProgressMessage'], data['emailProgressType']);
          break;
        case 'auth-register-cancel':
          this.signalRegisterCancel();
          break;
        case 'auth-register-resend':
          this.signalRegisterResend();
          break;
      }
    },
  },
  emits: ['switchMode'],
  methods: {
    ...mapActions(['setErrorReason', 'unsetErrorReason']),
    handleClickActionRegisterResend() {
      this.$wsSend('authRegisterResend', {});
    },
    handleClickActionRegisterConfirm() {
      this.$wsSend('authRegisterCode', {
        code: this.code,
      });
    },
    handleClickActionRegisterConfirmCancel() {
      this.$wsSend('authRegisterCancel', {});
    },
    signalRegisterConfirm() {
      this.unsetErrorReason();
      this.$store.dispatch('wsRegisterConfirm');
      this.$store.dispatch('setWelcomeScreen', true);
      this.$emit('switchMode', 'registerSuccess');
    },
    signalRegisterConfirmFail(payload) {
      this.setErrorReason(payload);
    },
    signalRegisterCancel() {
      this.unsetErrorReason();
      this.$store.dispatch('wsUnsetUser');
      this.$emit('switchMode', 'register');
    },
    signalEmailProgress(message, type) {
      this.unsetErrorReason();
      this.user.register.emailProgressMessage = message;
      this.user.register.emailProgressType = type;
      this.emailProgress = message === null ? 'Prepare to sending ...' : message;
      switch (type) {
        case 'error':
          this.emailProgressStyle = 'bg-danger';
          break;
        case null:
        case 'info':
        default:
          this.emailProgressStyle = 'bg-info';
          break;
      }
    },
    signalRegisterResend() {
      this.emailProgress = 'Prepare to sending ...';
      this.emailProgressStyle = 'bg-info';
    },
  },
  mounted() {
    this.email = this.user.email;
    this.emailProgress = this.user.register.emailProgressMessage === null ? 'Prepare to sending ...' : this.user.register.emailProgressMessage;
    switch (this.user.emailProgressType) {
      case 'error':
        this.emailProgressStyle = 'bg-danger';
        break;
      case null:
      case 'info':
      default:
        this.emailProgressStyle = 'bg-info';
        break;
    }
  },
}
</script>

<style scoped>

</style>