<template>
  <div class="col-md-4 offset-md-4">
    <div class="login-form mt-4 p-4">
      <div class="row g-3">
        <h4>Восстановить аккаунт</h4>
        <div class="col-12">
          <label>Почта</label>
          <input type="email" name="email" class="form-control" placeholder="Email" v-model="email">
        </div>
        <div class="col-12 error-placeholder">
          <error-label/>
        </div>
        <div class="col-12">
          <button type="submit" class="btn btn-dark float-end" @click="handleClickActionRestore" :disabled="disabledSubmit">Восстановить</button>
        </div>
      </div>
      <hr class="mt-4">
      <div class="col-12 mode-switcher-placeholder">
        <p class="text-center mb-0">Есть аккаунт? <a href="#" @click="handleClickModeLogin" data-mode="login">Войти</a></p>
      </div>
    </div>
  </div>
</template>

<script>
import ErrorLabel from "@/components/ui/ErrorLabel.vue";
import {latestMessage} from "@/plugins/ws";
import {mapActions, mapState} from "vuex";

export default {
  name: "ComponentRestore",
  components: {
    ErrorLabel,
  },
  computed: {
    ...mapState(['errorReason']),
  },
  data() {
    return {
      email: '',
      latestMessageValue: latestMessage,
      disabledSubmit: false,
    }
  },
  emits: ['switchMode'],
  watch: {
    email () {
      this.unsetErrorReason();
    },
    latestMessageValue(data) {
      switch (data['signal']) {
        case 'auth-restore':
          this.signalRestore(data['email']);
          break;
        case 'auth-restore-fail':
          this.signalRestoreFail(data['reason']);
          break;
      }
    },
  },
  methods: {
    ...mapActions(['setErrorReason', 'unsetErrorReason']),
    handleClickActionRestore() {
      this.$wsSend('authRestore', {
        email: this.email,
      });
      this.disabledSubmit = true;
    },
    handleClickModeLogin() {
      this.$emit('switchMode', 'login');
    },
    signalRestore(email) {
      this.unsetErrorReason();
      this.disabledSubmit = false;
      this.$store.dispatch('wsRestore', email);
      this.$emit('switchMode', 'restoreCode');
    },
    signalRestoreFail(payload) {
      this.setErrorReason(payload);
      this.disabledSubmit = false;
    },
  },
}
</script>

<style scoped>

</style>