<template>
  <div/>
</template>

<script>
import {latestMessage} from "@/plugins/ws";
import {mapActions} from "vuex";

export default {
  name: "dataLanguages",
  data() {
    return {
      latestMessageValue: latestMessage,
    }
  },
  watch: {
    latestMessageValue(data) {
      switch (data['signal']) {
        case 'language-root':
        case 'language-locale-list':
          this.wsLanguageRoot({language: data['language']});
          break;
        case 'language-list':
        case 'language-country-name':
          this.wsLanguages({languages: data['languages']});
          break;
        case 'language-create':
        case 'language-create-success':
          this.wsLanguagesCreate({
            idLanguage: data.idLanguage,
            code: data.code,
            selfTitle: data.selfTitle,
            rtl: data.rtl,
          });
          break;
        case 'language-update':
        case 'language-update-success':
          this.wsLanguagesUpdate({
            idLanguage: data.idLanguage,
            code: data.code,
            selfTitle: data.selfTitle,
            rtl: data.rtl,
          });
          break;
        case 'language-delete':
        case 'language-delete-success':
          this.wsLanguagesDelete({
            idLanguage: data.idLanguage,
          });
          break;
        case 'language-lock':
        case 'language-lock-success':
          this.wsLanguagesLock({
            idLanguage: data.idLanguage,
          });
          break;
        default:
          if (typeof data['languages'] != 'undefined') {
            this.wsLanguages({languages: data['languages']});
          }
          if (typeof data['recommendedLanguages'] != 'undefined') {
            this.wsRecommendedLanguages({recommendedLanguages: data['recommendedLanguages']});
          }
          break;
      }
    },
  },
  methods: {
    ...mapActions([
      'wsLanguages',
      'wsLanguageRoot',
      'wsLanguagesCreate',
      'wsLanguagesUpdate',
      'wsLanguagesDelete',
      'wsLanguagesLock',
      'wsRecommendedLanguages',
    ]),
  },
}
</script>
