<template>
  <span
      class="badge"
      :class="{
        'bg-dark text-dark': (message === ' ' && !style && (theme === 'dark')),
        'bg-light text-light': (message === ' ' && !style && (theme === 'light')),
        [style]: true,
        }"
  >
    {{ message }}
  </span>
</template>

<script>

import {mapState} from "vuex";

export default {
  name: "StatusLabel",
  computed: {
    ...mapState(['theme']),
  },
  props: {
    style: {
      type: [String, Boolean],
      default: false,
    },
    message: {
      type: String,
      default: '',
    },
  },
}
</script>

<style scoped>

</style>