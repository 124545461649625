import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'Main',
    meta: { access: 'guest' },
    component: () => import('../pages/Main.vue')
  },
  {
    path: '/about',
    name: 'About',
    meta: { access: 'guest' },
    component: () => import('../pages/About.vue')
  },
  {
    path: '/terms',
    name: 'Terms',
    meta: { access: 'guest' },
    component: () => import('../pages/Terms.vue')
  },
  {
    path: '/privacy',
    name: 'Privacy',
    meta: { access: 'guest' },
    component: () => import('../pages/Privacy.vue')
  },
  {
    path: '/contacts',
    name: 'Contacts',
    meta: { access: 'guest' },
    component: () => import('../pages/Contacts.vue')
  },
  {
    path: '/pairs',
    name: 'Pairs',
    meta: { access: 'guest' },
    component: () => import('../pages/Pairs.vue')
  },
  {
    path: '/pair',
    redirect: '/pairs',
  },
  {
    path: '/pair/:codePrimary-:codeSecondary',
    name: 'PagePairRoot',
    meta: { access: 'guest' },
    component: () => import('../pages/pair/Root.vue')
  },
  {
    path: '/pair/:codePrimary-:codeSecondary/graph',
    name: 'PagePairGraph',
    meta: { access: 'guest' },
    component: () => import('../pages/pair/Graph.vue')
  },
  {
    path: '/pair/:codePrimary-:codeSecondary/blockchains',
    name: 'PagePairBlockchains',
    meta: { access: 'guest' },
    component: () => import('../pages/pair/Blockchains.vue')
  },
  {
    path: '/profile',
    name: 'Profile',
    meta: { access: 'user' },
    component: () => import('../pages/Profile.vue')
  },
  {
    path: '/helper',
    name: 'HelperRoot',
    meta: { access: 'user' },
    component: () => import('../pages/helper/Root.vue')
  },
  {
    path: '/helper/wallet',
    redirect: '/helper/wallets',
  },
  {
    path: '/helper/wallets',
    name: 'HelperWallets',
    meta: { access: 'user' },
    component: () => import('../pages/helper/Wallets.vue')
  },
  {
    path: '/helper/account',
    redirect: '/helper/accounts',
  },
  {
    path: '/helper/accounts',
    name: 'HelperAccounts',
    meta: { access: 'user' },
    component: () => import('../pages/helper/Accounts.vue')
  },
  {
    path: '/admin',
    name: 'AdminRoot',
    meta: { access: 'admin' },
    component: () => import('../pages/Admin.vue'),
  },
  {
    path: '/admin/language',
    name: 'AdminLanguage',
    meta: { access: 'admin' },
    component: () => import('../pages/admin/Language.vue'),
  },
  {
    path: '/admin/language/:idLanguage',
    name: 'AdminLanguageRoot',
    meta: { access: 'admin' },
    component: () => import('../pages/admin/language/Root.vue'),
  },
  {
    path: '/admin/language/:idLanguage/locale',
    name: 'AdminLanguageLocale',
    meta: { access: 'admin' },
    component: () => import('../pages/admin/language/Locale.vue'),
  },
  {
    path: '/admin/language/country',
    name: 'AdminLanguageCountry',
    meta: { access: 'admin' },
    component: () => import('../pages/admin/language/Country.vue'),
  },
  {
    path: '/admin/language/country/:idCountry',
    name: 'AdminLanguageCountryRoot',
    meta: { access: 'admin' },
    component: () => import('../pages/admin/language/country/Root.vue'),
  },
  {
    path: '/admin/language/country/:idCountry/name',
    name: 'AdminLanguageCountryName',
    meta: { access: 'admin' },
    component: () => import('../pages/admin/language/country/Name.vue'),
  },
  {
    path: '/admin/language/entity',
    name: 'AdminLanguageEntity',
    meta: { access: 'admin' },
    component: () => import('../pages/admin/language/Entity.vue'),
  },
  {
    path: '/admin/language/page',
    name: 'AdminLanguagePage',
    meta: { access: 'admin' },
    component: () => import('../pages/admin/language/Page.vue'),
  },
  {
    path: '/admin/language/email',
    name: 'AdminLanguageEmail',
    meta: { access: 'admin' },
    component: () => import('../pages/admin/language/Email.vue'),
  },
  {
    path: '/admin/indicator',
    name: 'AdminIndicator',
    meta: { access: 'admin' },
    component: () => import('../pages/admin/Indicator.vue'),
  },
  {
    path: '/admin/indicator/:idIndicator',
    name: 'AdminIndicatorRoot',
    meta: { access: 'admin' },
    component: () => import('../pages/admin/indicator/Root.vue'),
  },
  {
    path: '/admin/indicator/:idIndicator/param',
    name: 'AdminIndicatorParam',
    meta: { access: 'admin' },
    component: () => import('../pages/admin/indicator/Param.vue'),
  },
  {
    path: '/admin/indicator/:idIndicator/out',
    name: 'AdminIndicatorOut',
    meta: { access: 'admin' },
    component: () => import('../pages/admin/indicator/Out.vue'),
  },
  {
    path: '/admin/indicator/:idIndicator/set',
    name: 'AdminIndicatorSet',
    meta: { access: 'admin' },
    component: () => import('../pages/admin/indicator/Set.vue'),
  },
  {
    path: '/admin/currency',
    name: 'AdminCurrency',
    meta: { access: 'admin' },
    component: () => import('../pages/admin/Currency.vue'),
  },
  {
    path: '/admin/currency/:idCurrency',
    name: 'AdminCurrencyRoot',
    meta: { access: 'admin' },
    component: () => import('../pages/admin/currency/Root.vue'),
  },
  {
    path: '/admin/currency/:idCurrency/blockchain',
    name: 'AdminCurrencyBlockchain',
    meta: { access: 'admin' },
    component: () => import('../pages/admin/currency/Blockchain.vue'),
  },
  {
    path: '/admin/global/currency', // TODO: rename to '/admin/currency/global'
    name: 'AdminGlobalCurrency',
    meta: { access: 'admin' },
    component: () => import('../pages/admin/global/Currency.vue'),
  },
  {
    path: '/admin/global/pair',
    name: 'AdminGlobalPair',
    meta: { access: 'admin' },
    component: () => import('../pages/admin/global/Pair.vue'),
  },
  {
    path: '/admin/global/pair/:idGlobalPair',
    name: 'AdminGlobalPairRoot',
    meta: { access: 'admin' },
    component: () => import('../pages/admin/global/pair/Root.vue'),
  },
  {
    path: '/admin/global/pair/:idGlobalPair/exchange',
    name: 'AdminGlobalPairExchange',
    meta: { access: 'admin' },
    component: () => import('../pages/admin/global/pair/Exchange.vue'),
  },
  {
    path: '/admin/global/pair/:idGlobalPair/indicator',
    name: 'AdminGlobalPairIndicator',
    meta: { access: 'admin' },
    component: () => import('../pages/admin/global/pair/Indicator.vue'),
  },
  {
    path: '/admin/global/pair/:idGlobalPair/calc',
    name: 'AdminGlobalPairCalc',
    meta: { access: 'admin' },
    component: () => import('../pages/admin/global/pair/Calc.vue'),
  },
  {
    path: '/admin/global/pair/:idGlobalPair/data',
    name: 'AdminGlobalPairData',
    meta: { access: 'admin' },
    component: () => import('../pages/admin/global/pair/Data.vue'),
  },
  {
    path: '/admin/tradeGroup',
    name: 'AdminTradeGroup',
    meta: { access: 'admin' },
    component: () => import('../pages/admin/TradeGroup.vue'),
  },
  {
    path: '/admin/setting',
    name: 'AdminSetting',
    meta: { access: 'admin' },
    component: () => import('../pages/admin/Setting.vue'),
  },
  {
    path: '/admin/backup',
    name: 'AdminBackup',
    meta: { access: 'admin' },
    component: () => import('../pages/admin/Backup.vue'),
  },
  {
    path: '/admin/store',
    name: 'AdminStore',
    meta: { access: 'admin' },
    component: () => import('../pages/admin/Store.vue'),
  },
  {
    path: '/admin/store/:indexExchange',
    name: 'AdminStoreExchange',
    meta: { access: 'admin' },
    component: () => import('../pages/admin/store/Exchange.vue'),
  },
  {
    path: '/admin/stream',
    name: 'AdminStream',
    meta: { access: 'admin' },
    component: () => import('../pages/admin/Stream.vue'),
  },
  {
    path: '/admin/stream/:indexExchange',
    name: 'AdminStreamExchange',
    meta: { access: 'admin' },
    component: () => import('../pages/admin/stream/Exchange.vue'),
  },
  {
    path: '/admin/calc',
    name: 'AdminCalc',
    meta: { access: 'admin' },
    component: () => import('../pages/admin/Calc.vue'),
  },
  {
    path: '/admin/calc/logs',
    name: 'AdminCalcLogs',
    meta: { access: 'admin' },
    component: () => import('../pages/admin/calc/Logs.vue'),
  },
  {
    path: '/admin/tasks',
    name: 'AdminTasks',
    meta: { access: 'admin' },
    component: () => import('../pages/admin/Tasks.vue'),
  },
  {
    path: '/admin/exchange',
    name: 'AdminExchange',
    meta: { access: 'admin' },
    component: () => import('../pages/admin/Exchange.vue'),
  },
  {
    path: '/admin/exchange/:idExchange',
    name: 'AdminExchangeRoot',
    meta: { access: 'admin' },
    component: () => import('../pages/admin/exchange/Root'),
  },
  {
    path: '/admin/exchange/:idExchange/currency',
    name: 'AdminExchangeCurrency',
    meta: { access: 'admin' },
    component: () => import('../pages/admin/exchange/Currency'),
  },
  {
    path: '/admin/exchange/:idExchange/pair',
    name: 'AdminExchangePair',
    meta: { access: 'admin' },
    component: () => import('../pages/admin/exchange/Pair'),
  },
  {
    path: '/admin/user',
    name: 'AdminUser',
    meta: { access: 'admin' },
    component: () => import('../pages/admin/User.vue'),
  },
  {
    path: '/admin/blockchain',
    name: 'AdminBlockchain',
    meta: { access: 'admin' },
    component: () => import('../pages/admin/Blockchain.vue'),
  },
  {
    path: '/:catchAll(.*)',
    name: 'PageNotFound',
    component: () => import('../pages/404.vue'),
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
