<template>
  <div class="container">
    <header class="d-flex flex-wrap justify-content-between align-items-center py-3 mb-4 border-bottom sticky-top">
      <router-link to="/" class="navbar-brand">
        <span class="fs-4">Хлебушек</span>
      </router-link>

      <ul class="nav nav-pills mt-1 mt-md-0" v-if="wsConnected && isSessionInit">
        <li class="nav-item">
          <router-link to="/admin" class="btn btn-outline-info border-0" v-if="$store.state.user.admin">
            <i class="bi bi-gear-fill"></i>
          </router-link>
        </li>
        <li class="nav-item dropdown">
          <button class="btn btn-outline-info dropdown-toggle border-0" type="button" id="languageDropdown" data-bs-toggle="dropdown" aria-expanded="false">
            <i class="bi bi-translate"></i>
          </button>
          <ul class="dropdown-menu dropdown-menu-scroll" aria-labelledby="languageDropdown">
            <li v-for="recommendedLanguagesBlock in recommendedLanguagesBlocks" :key="recommendedLanguagesBlock.idLanguage">
              <span
                  class="dropdown-item"
                  :class="{
                    'link-alert disabled' : recommendedLanguagesBlock.recommendedLocales.length > 1,
                    'link-info' : recommendedLanguagesBlock.recommendedLocales.length === 1,
                  }"
                  @click="selectLanguage(recommendedLanguagesBlock.idLanguage)"
              > {{ recommendedLanguagesBlock.selfTitle }}</span>
              <span v-if="recommendedLanguagesBlock.recommendedLocales.length > 1">
                <span
                    v-for="recommendedLocale in recommendedLanguagesBlock.recommendedLocales"
                    class="dropdown-item link-info ps-custom"
                    :key="recommendedLocale.idLocale"
                    @click="selectLanguage(recommendedLanguagesBlock.idLanguage, recommendedLocale.idLocale)"
                >[ {{ recommendedLocale.countryCode }} ] {{ recommendedLocale.countryName }}</span>
              </span>
            </li>
            <li><hr class="dropdown-divider"></li>
            <li class="">
              <span class="dropdown-item link-info d-flex justify-content-center" @click="selectLanguageModal()">Все языки</span>
            </li>
            <li><hr class="dropdown-divider"></li>
            <li v-for="language in allLanguages" :key="language.idLanguage">
              <span class="dropdown-item link-info" @click="selectLanguage(language.idLanguage)">{{ language.selfTitle }}</span>
            </li>
          </ul>
        </li>
        <li class="nav-item dropdown" id="dropdownThemeSwitcher" v-if="themeDoNotShow === false">
          <span class="btn btn-outline-info dropdown-toggle border-0" role="button" data-bs-toggle="dropdown" aria-expanded="false">
            <i :class="currentIcon"></i>
          </span>
          <ul class="dropdown-menu" aria-labelledby="themeSwitcher">
            <li><span class="dropdown-item" @click="setTopMenuTheme('light')"><i class="bi bi-brightness-high"></i> Светлая</span></li>
            <li><span class="dropdown-item" @click="setTopMenuTheme('dark')"><i class="bi bi-moon"></i> Тёмная</span></li>
            <li><span class="dropdown-item" @click="setTopMenuTheme('auto')"><i class="bi bi-circle-half"></i> Авто</span></li>
            <li><hr class="dropdown-divider"></li>
            <li><span class="dropdown-item" @click="hideTopMenuTheme()"><i class="bi bi-x-lg"></i> Убрать из меню</span></li>
          </ul>
        </li>
        <li class="nav-item">
          <router-link to="/profile" class="btn btn-outline-info dropdown-toggle border-0" v-if="!$store.state.user.name">
            <i class="bi bi-person"></i>
          </router-link>
        </li>
        <li class="nav-item dropdown" v-if="$store.state.user.name" id="dropdownProfile">
          <button class="btn btn-outline-info dropdown-toggle border-0" role="button" data-bs-toggle="dropdown" aria-expanded="false">
            {{ $store.state.user.name }}
            <i class="bi bi-person-circle"></i>
          </button>
          <ul class="dropdown-menu">
            <li>
              <router-link to="/helper" class="dropdown-item">Помощник</router-link>
            </li>
            <li>
              <hr class="dropdown-divider">
            </li>
            <li>
              <router-link to="/profile" class="dropdown-item">Профиль</router-link>
            </li>
            <li>
              <hr class="dropdown-divider">
            </li>
            <li>
              <a class="dropdown-item" href="javascript:void(0)" @click="actionLogout">Выход</a>
            </li>
          </ul>
        </li>
      </ul>
      <ul class="nav nav-pills mt-1 mt-md-0" v-else>
        <li class="nav-item">
          <div class="spinner-border" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </li>
      </ul>
    </header>
  </div>
</template>

<script>

import {mapActions, mapGetters, mapState} from "vuex";

export default {
  name: "TopMenu",
  data() {
    return {
      themes: {
        none: 'bi bi-slash-circle',
        light: 'bi bi-brightness-high',
        dark: 'bi bi-moon',
        auto: 'bi bi-circle-half'
      },
      themeInterval: null,
    };
  },
  props: {
    isAdminPanel: Boolean,
  },
  computed: {
    ...mapState(['wsLoaded', 'wsConnected', 'theme', 'themeDoNotShow', 'user']),
    ...mapState(['languages', 'locales', 'countries', 'countryLanguages', 'recommendedLanguages']),
    ...mapGetters(['isSessionInit']),
    currentIcon() {
      return this.themes[this.theme ?? 'none'];
    },
    recommendedLanguagesBlocks() {
      let ret = [];

      Object.keys(this.recommendedLanguages).map(recommendedLanguageIndex => {
        let recommendedLanguage = this.recommendedLanguages[recommendedLanguageIndex];
        let language = this.languages.find(language => language.code === recommendedLanguage.code);
        if (typeof language == 'undefined') {
          return;
        }
        let recommendedLocales = [];
        Object.keys(recommendedLanguage.recommendedLocales).map(recommendedLocaleIndex => {
          let recommendedLocale = recommendedLanguage.recommendedLocales[recommendedLocaleIndex];
          let locale = this.locales.find(locale => locale.idLocale === recommendedLocale.idLocale);
          let country = this.countries.find(country => country.idCountry === locale.idCountry);
          let countryLanguage = this.countryLanguages.find(countryLanguage => countryLanguage.idCountry === locale.idCountry && countryLanguage.idLanguage === locale.idLanguage);
          recommendedLocales.push({
            idLocale: locale.idLocale,
            idCountry: locale.idCountry,
            countryCode: (typeof country != 'undefined') ? country.code : '--',
            countryName: (typeof countryLanguage != 'undefined') ? countryLanguage.name : '--',
            weight: recommendedLocale.weight,
          });
          recommendedLocales.sort((a, b) => a.weight < b.weight ? 1 : (a.weight > b.weight ? -1 : 0));
        });
        ret.push({
          idLanguage: language.idLanguage,
          selfTitle: language.selfTitle,
          weight: recommendedLanguage.weight,
          code: recommendedLanguage.code,
          recommendedLocales: recommendedLocales,
        });
      });

      ret.sort((a, b) => a.weight < b.weight ? 1 : (a.weight > b.weight ? -1 : 0));

      return ret;
    },
    allLanguages() {
      return this.languages
          .filter(language => language.locked === true)
          .sort((a, b) => a.code.localeCompare(b.code));
    },
  },
  watch: {
    theme() {
      this.applyTheme();
    }
  },
  emits: ['actionLogout'],
  methods: {
    ...mapActions(['setTheme', 'setThemeDoNotShow']),
    actionLogout() {
      this.$emit('actionLogout');
    },
    applyTheme() {
      if (this.theme === null) {
        document.documentElement.removeAttribute('data-bs-theme');
      } else if (this.theme === 'auto') {
        const autoMode = window.matchMedia('(prefers-color-scheme: light)').matches;

        document.documentElement.setAttribute('data-bs-theme', autoMode ? 'light' : 'dark');
      } else {
        document.documentElement.setAttribute('data-bs-theme', this.theme);
      }
    },
    updateThemeIfAuto() {
      if (this.theme === 'auto') {
        this.applyTheme();
      }
    },
    setTopMenuTheme(theme) {
      this.setTheme(theme);
      if (this.user.theme !== this.theme && this.user.idUser !== null) {
        this.$wsSend('profileUpdateTheme', {
          newTheme: theme,
        });
      }
    },
    selectLanguageModal() {
      console.log('selectLanguageModal');
    },
    selectLanguage(idLanguage, idLocale) {
      console.log('selectLanguage', idLanguage, idLocale);
    },
    hideTopMenuTheme() {
      this.setThemeDoNotShow(true);
    },
  },
  mounted() {
    this.applyTheme();
    this.themeInterval = setInterval(this.updateThemeIfAuto, 60000);
  },
  beforeUnmount() {
    if (this.themeInterval) {
      clearInterval(this.themeInterval);
    }
  },
}
</script>

<style scoped>
span.dropdown-item {
  cursor: pointer;
}
.dropdown-item .bi {
  margin-right: 10px;
}
.ps-custom {
  padding-left: 2rem !important;
}
.dropdown-menu-scroll {
  max-height: 300px; /* Максимальная высота для отображения элементов */
  overflow-y: auto; /* Добавляет прокрутку по вертикали */
}
</style>