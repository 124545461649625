<template>
  <div/>
</template>

<script>
import {latestMessage} from "@/plugins/ws";
import {mapActions} from "vuex";

export default {
  name: "dataCountryLanguages",
  data() {
    return {
      latestMessageValue: latestMessage,
    }
  },
  watch: {
    latestMessageValue(data) {
      switch (data['signal']) {
        case 'language-country-name':
        case 'language-locale-list':
          this.wsCountryLanguages({countryLanguages: data['countryLanguages']});
          break;
        case 'language-country-name-create':
        case 'language-country-name-create-success':
          this.wsCountryLanguagesCreate({
            idCountry: data.idCountry,
            idLanguage: data.idLanguage,
            name: data.name,
          });
          break;
        case 'language-country-name-update':
        case 'language-country-name-update-success':
          this.wsCountryLanguagesUpdate({
            idCountry: data.idCountry,
            idLanguage: data.idLanguage,
            name: data.name,
          });
          break;
        case 'language-country-name-delete':
        case 'language-country-name-delete-success':
          this.wsCountryLanguagesDelete({
            idCountry: data.idCountry,
            idLanguage: data.idLanguage,
          });
          break;
        case 'language-country-name-lock':
        case 'language-country-name-lock-success':
          this.wsCountryLanguagesLock({
            idCountry: data.idCountry,
            idLanguage: data.idLanguage,
          });
          break;
        default:
          if (typeof data['countryLanguages'] != 'undefined') {
            this.wsCountryLanguages({countryLanguages: data['countryLanguages']});
          }
          break;
      }
    },
  },
  methods: {
    ...mapActions([
      'wsCountryLanguages',
      'wsCountryLanguagesCreate',
      'wsCountryLanguagesUpdate',
      'wsCountryLanguagesDelete',
      'wsCountryLanguagesLock',
    ]),
  },
}
</script>
