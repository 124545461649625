<template>
  <Login v-if="authMode === 'login' || authMode === null" @switchMode="switchMode"></Login>
  <Register v-if="authMode === 'register'" @switchMode="switchMode"></Register>
  <RegisterCode v-if="authMode === 'registerCode'" @switchMode="switchMode"></RegisterCode>
  <RegisterSuccess v-if="authMode === 'registerSuccess'" @switchMode="switchMode" @resubscribePage="resubscribePage"></RegisterSuccess>
  <Restore v-if="authMode === 'restore'" @switchMode="switchMode"></Restore>
  <RestoreCode v-if="authMode === 'restoreCode'" @switchMode="switchMode"></RestoreCode>
  <RestoreConfirm v-if="authMode === 'restoreConfirm'" @switchMode="switchMode"></RestoreConfirm>
  <RestoreSuccess v-if="authMode === 'restoreSuccess'" @switchMode="switchMode" @resubscribePage="resubscribePage"></RestoreSuccess>
</template>

<script>
import Login from "./auth/Login"
import Register from "./auth/Register"
import RegisterCode from "./auth/RegisterCode"
import RegisterSuccess from "./auth/RegisterSuccess"
import Restore from "./auth/Restore"
import RestoreCode from "./auth/RestoreCode"
import RestoreConfirm from "./auth/RestoreConfirm"
import RestoreSuccess from "./auth/RestoreSuccess"
import {mapActions, mapState} from "vuex";

export default {
  name: "ComponentAuth",
  components: {
    Login,
    Register,
    RegisterCode,
    RegisterSuccess,
    Restore,
    RestoreCode,
    RestoreConfirm,
    RestoreSuccess,
  },
  emits: ['resubscribePage'],
  computed: {
    ...mapState(['user', 'authMode', 'welcomeScreen']),
  },
  methods: {
    ...mapActions(['setAuthMode']),
    switchMode(mode) {
      this.setAuthMode(mode);
    },
    resubscribePage() {
      this.$emit('resubscribePage');
    },
  },
}
</script>

<style scoped>

</style>